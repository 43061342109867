<template>
  <v-row
    align="center"
    justify="center">
    <v-col md="8">
      <v-card :loading="loading">
        <v-card-title>
          {{ $t("crudActions.createModel", { model: $t("models.domains.single") }) }}
        </v-card-title>
        <v-card-text>
          <validation-observer
            ref="validationObserver"
            slim>
            <domain-form
              v-model="domain"
              :params="params"
              :validations="validationRules"
              @submit="create" />
          </validation-observer>
          <v-card-actions>
            <v-btn
              color="primary"
              @click="$router.push({ name: $routes.domain.indexRoute.name })">
              {{ $t("actions.back") }}
            </v-btn>
            <v-spacer />
            <v-btn
              color="success"
              @click="create">
              {{ $t("crudActions.create") }}
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { fetchParams, createDomain } from "@/api/domain.js";
import DomainForm from "../../components/domain/DomainForm.vue";
import ContentLoader from "../../mixins/ContentLoader.js";
import { ValidationObserver } from "vee-validate";
import Validatable from "../../mixins/Validatable.js";

export default {
  name: "DomainCreate",
  components: {
    DomainForm,
    ValidationObserver,
  },
  mixins: [ContentLoader, Validatable],
  data() {
    return {
      domain: {
        url: null,
        style: null,
      },
      params: {
        styles: [],
      },
    };
  },
  computed: {
    validationRules() {
      return {
        url: "required",
        style: "required",
      };
    },
  },
  created() {
    this.fetchParams();
  },
  methods: {
    create() {
      this.$refs.validationObserver.validate().
        then(isValid => {
          if (isValid) {
            this.$startLoading();
            createDomain(this.domain).
              then(() => {
                this.$router.push({ name: this.$routes.domain.indexRoute.name });
              }).
              catch(error => this.handleErrorResponse(this.$refs.validationObserver, error)).
              finally(() => {
                this.$finishedLoading();
              });
          }
        });
    },
    fetchParams() {
      this.$startLoading();
      fetchParams().
        then(({ data }) => {
          this.params.styles = data.styles;
        });
      this.$finishedLoading();
    },
  },
};
</script>
